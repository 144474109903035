import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

$(function() {
    $(".sp-drawer-btn").on("click", function() {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(".gnav")
          .addClass("close")
          .removeClass("open");
      } else {
        $(this).addClass("active");
        $(".gnav")
          .addClass("open")
          .removeClass("close");
      }
    });
    $(".js_close-link").on("click",function() {
      $(".sp-drawer-btn").removeClass("active");
      $(".gnav")
        .addClass("close")
        .removeClass("open");
    });

    // ページトップへボタン
    var $pageTop = $(".js_pageTop");
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $pageTop.fadeIn();
      } else {
        $pageTop.fadeOut();
      }
    });

    // ナビゲーションの現在地を表示
    $(function(){
      var pageURL = location.pathname,
          pageURLArr = pageURL.split('/'), //パスを分割して配列化する
          pageURLArrCategory = pageURLArr[1], //パスから第1階層を取得
          pageURLArrCategory_02 = pageURLArr[2];

      // Headerのナビゲーション
      $('.js_localNav li a').each(function () {
        var selfhref = $(this).attr('href'),
            hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
            hrefArrCategory = hrefArr[3], //href属性の第1階層を取得
            hrefArrCategory_02 = hrefArr[4];
        if (location.pathname == "/") {
          $('.js_localNav li a:eq(0)').addClass('current');
        } else if (pageURLArrCategory === hrefArrCategory && pageURLArrCategory_02 === hrefArrCategory_02) {
          $(this).addClass('current');
        } else if (pageURLArrCategory === hrefArrCategory && pageURLArrCategory_02 === '' && hrefArrCategory_02 === undefined) {
          $(this).addClass('current');
        } else if (hrefArrCategory === 'about-us' && hrefArrCategory === undefined) {
          $(this).addClass('current');
        }
      });

      // Projectのナビゲーション
      $('.js_localNavProject li a').each(function () {
        var selfhref = $(this).attr('href'),
            hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
            hrefArrCategory = hrefArr[3], //href属性の第1階層を取得
            hrefArrCategory_02 = hrefArr[4];
        if (pageURLArrCategory === hrefArrCategory && pageURLArrCategory_02 === '' && hrefArrCategory_02 === undefined) {
          $(this).addClass('current');
        } else if (pageURLArrCategory === hrefArrCategory && pageURLArrCategory_02 === hrefArrCategory_02) {
          $(this).addClass('current');
        }
      });
    });

});